<script setup lang="ts">
import type { IManagerFieldEmits, IManagerFieldProps } from './types'
import { useFieldCache, useFieldCalculation, useFieldCast } from '@manager'

const props = withDefaults(defineProps<IManagerFieldProps>(), {
  parentValue: () => ({}),
})
const emit = defineEmits<IManagerFieldEmits>()

const parentValue = useVModel(props, 'parentValue', emit)
const { setCalculated } = useFieldCalculation(props.node, parentValue)
const { cast, castPlugin } = useFieldCast(props.node)

// Cast value and fallback to default
parentValue.value[props.node.name] = cast(
  parentValue.value[props.node.name],
  props.node.defaultValue,
)

useFieldCache(props.node, parentValue)
</script>

<template>
  <ManagerField :node="node">
    <template
      #default="{ id, name, type, label, validation, validationMessages }"
    >
      <FormKit
        :id="id"
        v-model="parentValue[name]"
        :type="type"
        :name="name"
        :label="label"
        label-class="overflow-ellipsis whitespace-nowrap overflow-hidden"
        :plugins="[castPlugin]"
        :validation="validation"
        :validation-messages="validationMessages"
        validation-visibility="live"
        :min-date="node.min ?? undefined"
        :max-date="node.min ?? undefined"
        @input="setCalculated"
      >
        <template v-if="node.prefix" #prefix>
          <span
            class="flex h-10 items-center rounded-l-[5px] bg-gray-700 px-2.5 pt-px text-xs"
          >
            {{ node.prefix }}
          </span>
        </template>
        <template v-if="node.suffix" #suffix>
          <span
            class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
          >
            {{ node.suffix }}
          </span>
        </template>
      </FormKit>
    </template>
  </ManagerField>
</template>
